<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("COMPANY") }}</h1>
    </div>
    <b-form
      @submit.prevent="update"
      v-if="!getentrepriseLoading && form"
      class="content"
    >
      <div class="pageBlock">
        <div class="left">
          <div class="image" v-if="!form.logo">
            <div class="profil" v-if="form.lib">
              <span>{{ form.lib.slice(0, 1) }}</span>
            </div>
          </div>

          <div class="image" v-else>
            <div class="profil">
              <img v-if="previewImage" :src="previewImage" alt="logo" />
              <img v-else :src="downloadUrl + form.logo" alt="logo" />
            </div>
          </div>

          <label for="file_input" v-if="isentrepriseAdmin"
            >{{ $t("LOGO") }} {{ $t("ENTREPRISE") }}</label
          >
          <label v-else>{{ $t("LOGO") }} {{ $t("ENTREPRISE") }}</label>
          <p
            v-if="form.logo && isentrepriseAdmin"
            style="color: red; cursor: pointer; margin: 0"
            @click="form.logo = null"
          >
            {{ $t("ANNULER") }}
          </p>
          <b-form-file
            v-if="!form.logo"
            v-model="form.logo"
            id="file_input"
            style="display: none"
            accept="image/*"
            @change="chargeImg"
          ></b-form-file>

          <b-form-file
            v-else
            v-model="form.file"
            id="file_input"
            style="display: none"
            accept="image/*"
            @change="chargeImg"
          ></b-form-file>
          <h3>{{ form.lib }}</h3>
          <h4>{{ form.email }}</h4>

          <div class="logoRGE">
            <div class="image" v-if="!form.logo_rge">
              <div class="profil" v-if="form.lib">
                <span>{{ form.lib.slice(0, 1) }}</span>
              </div>
            </div>
            <div class="image" v-else>
              <div class="profil">
                <img v-if="previewNewImage" :src="previewNewImage" alt="logo" />
                <img v-else :src="downloadUrl + form.logo_rge" alt="logo" />
              </div>
            </div>
            <label for="logo_rge" v-if="isentrepriseAdmin">{{
              $t("LOGO_RGE")
            }}</label>
            <label v-else>{{ $t("LOGO_RGE") }}</label>
            <p
              v-if="form.logo_rge && isentrepriseAdmin"
              style="color: red; cursor: pointer"
              @click="form.logo_rge = null"
            >
              {{ $t("ANNULER") }}
            </p>
            <b-form-file
              v-model="form.logo_rge"
              id="logo_rge"
              accept="image/*"
              style="display: none"
              @change="chargeNewImg"
            ></b-form-file>
          </div>
        </div>
        <div class="right">
          <h2>{{ $t("INFORMATIONS") }}</h2>
          <div class="rightF">
            <b-form-group :label="$t('WORDING')" class="w-100">
              <b-form-input
                id="input-2"
                v-model="form.lib"
                :placeholder="$t('WORDING')"
                required
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>
          </div>
          <h2>{{ $t("ADRESS") }}</h2>
          <div class="rightF">
            <b-form-group :label="$t('COUNTRY')" v-if="getAllcountries">
              <multiselect
                v-model="form.pays"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllcountries"
                label="name"
                track-by="id"
                v-if="isentrepriseAdmin"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>

              <template v-else>
                <b-form-input
                  id="input-2"
                  v-model="form.pays.name"
                  :placeholder="$t('COUNTRY')"
                  readonly
                ></b-form-input>
              </template>
            </b-form-group>

            <b-form-group :label="$t('CITY')">
              <b-form-input
                id="input-2"
                v-model="form.ville"
                :placeholder="$t('CITY')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('ADRESS')">
              <b-form-input
                id="input-2"
                v-model="form.rue"
                :placeholder="$t('ADRESS')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('POSTAL_CODE')">
              <b-form-input
                id="input-2"
                type="number"
                v-model="form.cp"
                :placeholder="$t('POSTAL_CODE')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('PHONE')" class="w-100">
              <template v-if="isentrepriseAdmin">
                <phone
                  :tel="form.phone"
                  @phoneNumber="formatNumber($event)"
                ></phone>
              </template>
              <template v-else>
                <b-form-input
                  id="input-2"
                  v-model="form.phone.phone_number"
                  :placeholder="$t('PHONE')"
                  readonly
                ></b-form-input>
              </template>
            </b-form-group>
            <b-alert
              variant="warning"
              show
              v-if="!numberError && isentrepriseAdmin"
              class="w-100"
            >
              {{ $t("ERROR_PHONE") }}
            </b-alert>

            <b-form-group :label="$t('EMAIL')" class="w-100">
              <b-form-input
                id="input-2"
                v-model="form.email"
                type="email"
                :placeholder="$t('EMAIL')"
                required
                readonly
              ></b-form-input>
            </b-form-group>
            <!-- <div class="email" v-if="!email_activate">
              Pour envoyer des emails au client avec l'adresse email de
              l'entreprise, Vous devez la valider en cliquant sur ce bouton :
              <b-button variant="success" @click="confirm_email()">{{
                $t("VALIDATE")
              }}</b-button>
            </div>
            <div class="email" style="color: #ffb300" v-else>
              Adresse email validée
            </div> -->
          </div>

          <h2>{{ $t("INFO_BANK") }}</h2>
          <div class="rightF">
            <b-form-group :label="$t('ACCOUNT_OWNER')" class="w-100">
              <b-form-input
                id="input-2"
                v-model="form.titulaire_compte"
                :placeholder="$t('ACCOUNT_OWNER')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('SWIFT_BIC')">
              <b-form-input
                v-model="form.SWIFT_BIC"
                :placeholder="$t('SWIFT_BIC')"
                minlength="8"
                maxlength="11"
                oninput="this.value = this.value.toUpperCase()"
                required
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('BANK_ID_(IBAN)')">
              <b-form-input
                v-model="form.IBAN"
                :placeholder="$t('BANK_ID_(IBAN)')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('SIRET')">
              <b-form-input
                v-model="form.siret"
                :placeholder="$t('SIRET')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('VAT')">
              <b-form-input
                v-model="form.num_tva"
                :placeholder="$t('VAT')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('NOM_ASSURANCE')">
              <b-form-input
                v-model="form.nom_assurance"
                :placeholder="$t('NOM_ASSURANCE')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('NUM_ASSURANCE')">
              <b-form-input
                v-model="form.num_assurance"
                :placeholder="$t('NUM_ASSURANCE')"
                :readonly="!isentrepriseAdmin"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="submit">
            <p v-if="error">{{ $t("ERROR") }}</p>
            <b-button type="submit" v-if="isentrepriseAdmin">
              <font-awesome-icon icon="check" />
              {{ $t("SAVE") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-form>

    <b-modal id="Modaleinfo" ref="Modaleinfo">
      <template #modal-header="{ close }">
        <h5>{{ $t("NOTIF") }}</h5>
        <b-button size="sm" @click="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <div class="modalinfo">
        <p class="my-4">{{ $t("UPDATE_SUCCESS") }}</p>
      </div>
      <template #modal-footer="{ close }">
        <div class="double">
          <b-button variant="danger" @click="close">
            <div class="block-spinner">
              {{ $t("OK") }}
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>
    <CommentMarche to="/how-it-work/infoEntreprise"></CommentMarche>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { domains } from "@/environment";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "../components/ui/phone.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    phone,
    CommentMarche,
  },
  data() {
    return {
      form: null,
      error: null,
      previewImage: null,
      previewNewImage: null,
      downloadUrl: domains.download,
      numberError: true,
    };
  },

  methods: {
    ...mapActions([
      "edit_entreprise",
      "entreprise",
      "pays",
      "active_email_entreprise",
      "resend_active_email",
      "all_countries",
    ]),
    chargeImg(e) {
      var fileSize = e.target.files[0].size;
      var maxSize = 2 * 1024 * 1024;

      if (fileSize > maxSize) {
        this.$bvModal.msgBoxOk(
          this.$t("La taille du fichier dépasse la limite autorisée de 2 Mo"),
          {
            title: this.$t("ALERT"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        );
      } else {
        const logofile = e.target.files[0];
        this.previewImage = URL.createObjectURL(logofile);
      }
    },
    chargeNewImg(e) {
      var fileSize = e.target.files[0].size;
      var maxSize = 2 * 1024 * 1024;

      if (fileSize > maxSize) {
        this.$bvModal.msgBoxOk(
          this.$t("La taille du fichier dépasse la limite autorisée de 2 Mo"),
          {
            title: this.$t("ALERT"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        );
      } else {
        const logofile = e.target.files[0];
        this.previewNewImage = URL.createObjectURL(logofile);
      }
    },
    async update() {
      if (!this.numberError) {
        return;
      }
      // Format numero tel
      var num = this.form.phone.phone_number.indexOf(" ");
      this.form.phone = {
        phone_number: this.form.phone.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.form.phone.phone_iso_code
          ? this.form.phone.phone_iso_code
          : this.getentreprise.phone.iso_code,
      };
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.form.id);
      bodyFormData.append("lib", this.form.lib);
      bodyFormData.append("rue", this.form.rue);
      bodyFormData.append("ville", this.form.ville);
      bodyFormData.append("cp", this.form.cp);
      bodyFormData.append("tel", this.form.phone.phone_number);
      bodyFormData.append("phone_number", this.form.phone.phone_number);
      bodyFormData.append("phone_iso_code", this.form.phone.phone_iso_code);
      bodyFormData.append("email", this.form.email);
      bodyFormData.append("SWIFT_BIC", this.form.SWIFT_BIC);
      bodyFormData.append("IBAN", this.form.IBAN);
      bodyFormData.append("siret", this.form.siret);
      bodyFormData.append("num_tva", this.form.num_tva);
      bodyFormData.append("pays", this.form.pays.iso_code);
      bodyFormData.append("titulaire_compte", this.form.titulaire_compte);
      bodyFormData.append("nom_assurance", this.form.nom_assurance);
      bodyFormData.append("num_assurance", this.form.num_assurance);
      if (this.form.logo_rge)
        bodyFormData.append("logo_rge", this.form.logo_rge);

      if (this.form.file) bodyFormData.append("logo", this.form.file);
      else if (this.form.logo) bodyFormData.append("logo", this.form.logo);

      await this.edit_entreprise(bodyFormData)
        .then(() => {
          this.$refs["Modaleinfo"].show();
          this.entreprise(this.getOnlineUser.entreprise).then(() => {
            this.form = { ...this.getentreprise };
          });
          this.error = null;
        })
        .catch((err) => {
          this.error = err;
        });
    },

    formatNumber(event) {
      this.form.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.numberError = event.isValid;
    },

    confirm_email() {
      this.active_email_entreprise({
        email_u: this.getentreprise.email,
        name: this.getentreprise.lib,
      });
    },

    resend_email() {
      this.resend_active_email({
        email_u: this.getentreprise.email,
      });
    },
  },
  async mounted() {
    await this.entreprise(this.getOnlineUser.entreprise).then(() => {
      this.form = { ...this.getentreprise };
    });
    this.all_countries({ page: 1, perPage: 1000 });
  },
  computed: {
    ...mapGetters([
      "getentreprise",
      "getentrepriseLoading",
      "getTabs",
      "getOnlineUser",
      "getPays",
      "getSelectedLang",
      "getdictionnaire",
      "getAllcountries",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    email_activate() {
      if (this.getentreprise.email_activated_at) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  h1 {
    width: 100%;
  }
}
.container {
  @media only screen and (max-width: 750px) {
    width: 80%;
  }
  width: 95%;
  padding: 0px 35px 20px;
}

form {
  margin-top: 48px;
  background-color: #f5f6f8 !important;
  box-shadow: none !important;
  padding: 0 !important;

  hr {
    @media only screen and (max-width: 900px) {
      width: 90%;
    }
    border-top: 1px solid #707070;
    margin: 2rem auto;
    width: 60%;
  }

  .pageBlock {
    @media only screen and (max-width: 900px) {
      display: block;
    }
    display: flex;
    justify-content: space-between;
    .left {
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      padding: 8px;
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 1px 1px 24px #00000019;

      h3 {
        text-align: center;
        color: #393939;
        font-size: 24px;
        font-weight: 800;
        margin: 0 0 8px;
      }

      label {
        cursor: pointer;
        font-size: 12px;
        color: #5370ff;
        margin: 8px 0 0;

        &:hover {
          text-decoration: underline;
        }
      }

      #file_input {
        display: none;
      }

      h4 {
        margin: 0;
        color: #393939;
        font-size: 16px;
        font-style: italic;
      }

      .logoRGE {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        label {
          margin: 8px 0;
        }
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;

        .profil {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-color: #28367a;
          color: #fff;
          font-size: 58px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }

    .right {
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-top: 15px;
      }
      padding: 24px;
      background-color: #fff;
      box-shadow: 1px 1px 24px #00000019;
      width: 66%;

      h2 {
        width: 100%;
        font-size: 16px;
        font-weight: 800;
      }
    }

    .rightF {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      fieldset {
        width: 48%;
        margin: 0 0 15px;
        color: #28367a;
        font-size: 12px;

        input {
          padding: 0 16px;
          font-size: 14px;
        }
      }
    }
  }
}

.submit {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0 0;

  p {
    position: absolute;
    top: 0;
    color: #e4261b;
    margin: 0;
    margin-right: 32px;
    font-size: 12px;
  }

  .btn {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #34c38f;
    border-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    width: 35%;
    height: 42px;

    svg {
      margin-right: 10px;
      height: 18px;
      width: 18px;
    }
  }
}
.block-spinner {
  display: flex;
  justify-content: center;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.email {
  width: 100%;
  margin: 0 auto 8px;
  padding: 15px;
  font-size: 12px;
  background-color: #f5f6f8;
  color: #e4261b;
  border: 1px solid #ced4da;

  button {
    float: right;
    margin-top: 4px;
  }
}
.modalinfo {
  p {
    text-align: center;
    font-size: 21px;
  }
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
</style>
